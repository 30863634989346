import styles from './OverlayFrame.module.scss';
import cn from 'classnames';
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import Spinner from 'react-bootstrap/Spinner';

export const OverlayFrame = forwardRef(({ 
  header = '',
  subheader = '',
  loading = false,
  round = false,
  strokeColor = '#FFF'
}, ref) => {

  const containerRef = useRef(null);
  const frameRef = useRef(null);

  const [totalWidth, setTotalWidth] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  
  const updateWidth = () => {
    if(!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();

    const usableWidth = rect.width - (rect.width * 0.05);

    setTotalWidth(rect.width)
    setTotalHeight(rect.height)
    setWidth(usableWidth);
    setHeight(usableWidth * (round ? 1 : 0.7));
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    
    return () => {
      window.removeEventListener('resize', updateWidth);
    }
  }, []);

  // Allow parent to get frame rect
  useImperativeHandle(ref, () => ({
      getFrameRec() { 
        return frameRef?.current.getBoundingClientRect();
      }
    })
  )
  
  return (
    <div ref={containerRef} className={styles.root}>
      <svg width="100%" height="100%" viewBox={`0 0 ${totalWidth} ${totalHeight}`}>
        <mask id="transparent-mask">
          <rect width="100%" height="100%" fill="white"/>
          <rect
            x={(totalWidth - width) / 2}
            y={(totalHeight - height) / 2}
            width={`${width}px`}
            height={`${height}px`}
            ry={round ? '1000' : '10'}
            rx={round ? '1000' : '10'}
          />
        </mask>
        <rect width="100%" height="100%" fill="rgba(255, 255, 255, 1)" mask="url(#transparent-mask)"/>

        <rect 
          x={((totalWidth - width) / 2)}
          y={((totalHeight - height) / 2) - 60}
          width={`${width}px`}
          height='40'
          rx="20"
          ry="20"
          fill="#F9F9F9"
          stroke='#e5e5e5'
        />
        <text 
          x={totalWidth / 2} 
          y={((totalHeight - height) / 2) - 35} 
          font-family="inter" 
          font-size="16" 
          fill="#333"
          text-anchor="middle"
          fontWeight='600'
        >
          {header}
        </text>

        <rect
          ref={frameRef}
          x={(totalWidth - width - 4) / 2}
          y={(totalHeight - height - 4) / 2}
          width={`${width + 4}px`}
          height={`${height + 4}px`}
          ry={round ? '1000' : '12'}
          rx={round ? '1000' : '12'}
          fill="none"
          stroke={strokeColor}
          opacity='.5'
          strokeWidth="4"
          className={cn({ [styles.pulsing]: loading })}
        />
        <rect
          ref={frameRef}
          x={(totalWidth - width - 12) / 2}
          y={(totalHeight - height - 12) / 2}
          width={`${width + 12}px`}
          height={`${height + 12}px`}
          ry={round ? '1000' : '16'}
          rx={round ? '1000' : '16'}
          fill="none"
          stroke={strokeColor}
          opacity='.25'
          strokeWidth="4"
        />
        

        <rect 
          x={((totalWidth - width) / 2)}
          y={((totalHeight / 2) + (height / 2) + 20)} 
          width={`${width}px`}
          height='40'
          rx="20"
          ry="20"
          fill="#F9F9F9"
          stroke='#e5e5e5'
        />
        <text 
          x={totalWidth / 2} 
          y={((totalHeight / 2) + (height / 2) + 45)} 
          font-family="inter" 
          font-size="16" 
          fontWeight='600'
          fill="#333"
          text-anchor="middle"
        >
          {subheader}
        </text>
      </svg>
      {loading &&
        <Spinner animation="border" className={styles.spinner} />
      }
    </div>
  )
})