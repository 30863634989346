import styles from './SignIn.module.scss';
import { GoogleButton } from 'components/GoogleButton/GoogleButton';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Input, InputGroup, Button, FormMessage } from 'components/FormComponents'
import { Loading } from 'components/Loading/Loading';
import { CodeInput } from 'components/CodeInput/CodeInput';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Buffer } from 'buffer';
import { useDocumentTitle } from 'hooks/document-title';
import { SingleSignOnButton } from './components/SingleSignOnButton/SingleSignOnButton';
import { useQueryParams } from 'hooks/query-params';
import { selectAuthentication, verify2fa, login, loginWithGoogle } from 'api/authentication-slice';

const SignIn = () => {

  const dispatch = useDispatch();

  const authentication = useSelector(selectAuthentication)

  useDocumentTitle('Sign In');

  const navigate = useNavigate();

  const [ redirect_uri, state ] = useQueryParams(['redirect_uri', 'state']);

  const [loading, setLoading] = useState(false);
  const [messageText, setMessageText] = useState();

  const [userId, setUserId] = useState();
  const [mfa, setMfa] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  useEffect(() => {
    if(!authentication.checked) return;
    if(authentication.logged) redirect()
  }, [authentication])

  const redirect = () => {
    if(redirect_uri) {
      const query_params = state ? Buffer.from(state, 'base64').toString() : '';
      window.location.replace(`${redirect_uri}${query_params}`)
    } else {
      navigate('/dashboard')
    }
  }

  const onGoogleSign = async({ data: { email, access_token } }) => {
    setLoading(true);
    const { payload: response } = await dispatch(loginWithGoogle({ email, token: access_token }));
    if(response.error) {
      setLoading(true);
      setMessageText(response.error.message);
    } else {
      redirect();
    }
  }

  const onGoogleSignFailure = (error) => {
    console.log(error);
    setMessageText('Error getting google profile');
  }

  const onSubmit = async(data) => {
    setLoading(true);
    const { payload: response } = await dispatch(login({ email: data.email, password: data.password }));
    setLoading(false);

    if(response.error) {
      setMessageText(response.error.message);
      return;
    } else if (response.result.require_2fa) {
      setMfa(true);
      setUserId(response.result.user_id);
    } else {
      redirect();
    }
  }

  const onMFASubmittion = async(code) => {
    setLoading(true);
    const result = await dispatch(verify2fa({ user_id: userId, code }));
    setLoading(false);

    if(result.verified)
      redirect();
    else
      setMessageText('Invalid code. Try again!')
  }

  return (
    <div className={styles.root}>
      <Loading active={loading} />
      <div className={cn('card u-margin-top', 'card-with-shadow', styles.card)}>
        <div className={cn('card_content', styles.content)}>
        <img onClick={() => window.location.replace('https://www.checkmate.tech')} className={cn(styles.logo, 'u-margin-bottom--large')} src='/assets/images/checkmate/icon-label.svg' alt='KnowMy Logo' />
        <h2 className='design-marker title-2 u-margin-bottom--large'>Welcome back!</h2>
        {mfa ?
          <>
            <p>Two Factor Authentication Code</p>
            <CodeInput onComplete={onMFASubmittion} />
          </> :
          <>
            <GoogleButton onLoginSuccess={onGoogleSign} onLoginFailure={onGoogleSignFailure} />
            <SingleSignOnButton className='u-margin-top' onClick={() => navigate('/sso')} />
            <div className={styles.divider}><p>or</p><hr/></div>
            <form onSubmit={handleSubmit(onSubmit)} className='u-width-100 u-flex-box u-flex-align-center u-flex-direction-vertically'>
              <FormMessage message={messageText} className='u-margin-top--0' onClose={() => setMessageText(null)} />
              <InputGroup title='Email'>
                <Input 
                  name='email'
                  icon='mail'
                  placeholder='brad@checkmate.tech'
                  className='u-margin-bottom'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.email?.type}
                  inputProps={{ tabIndex: 1 }}
                />
              </InputGroup>
              <InputGroup title='Password'>
                <Link className={cn('u-link', 'dark', 'u-margin-top--small', styles.forgotPassword)} to="/forgot_password">Forgot your password?</Link>
                <Input 
                  name='password'
                  icon='lock'
                  type='password'
                  placeholder='Enter password'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.password?.type}
                  inputProps={{ tabIndex: 2 }}
                />
              </InputGroup>
              <Button submit buttonProps={{ tabIndex: 3 }} className='u-width-100 u-margin-top--large'>Log In</Button>
              <Link className={cn('u-link', 'dark', 'u-margin-top--large')} to="/sign_up">No account? Sign up</Link>
            </form>
          </>
          }
        </div>
      </div>
    </div>
  )
}

export default SignIn;