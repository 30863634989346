import "core-js/stable";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import HttpsRedirect from 'react-https-redirect';
import './styles/app.scss';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { analytics } from "services/segment";
import './helpers/string';

String.prototype.Capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

library.add(fab, fas);

analytics.load({ writeKey: 'DtfAXdc0MLtVLA97kwcDLZnr4DYck1j9' })

const root = createRoot(document.getElementById('App'));
root.render(
  <Provider store={store}>
    <HttpsRedirect>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </HttpsRedirect>
  </Provider>
);