export const cropImage = async (imageSrc, cropWidth = 100, cropHeight = 100) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    const image = new Image();
    image.src = imageSrc;

    // When the image loads, execute the cropping logic
    image.onload = () => {
      const imgWidth = image.width;
      const imgHeight = image.height;

      // Calculate startLeft and startTop to center the crop
      const startLeft = (imgWidth - cropWidth) / 2;
      const startTop = (imgHeight - cropHeight) / 2;

      // Set canvas size to the crop size
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      // Draw the cropped portion of the image from the center on the canvas
      ctx.drawImage(image, startLeft, startTop, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

      // Convert the canvas to a data URL (base64 encoded image)
      const croppedImage = canvas.toDataURL('image/jpeg');
      
      // Resolve the promise with the cropped image
      resolve(croppedImage);
    };

    // Handle any errors during image loading
    image.onerror = (err) => reject(err);
  });
}