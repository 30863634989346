export const CandidateCheckScreens = {
  INTRODUCTION: "introduction",
  QUESTIONNAIRE: "questionnaire",
  REFERENCE: "reference",
  CUSTOM_REFERENCE: "custom_reference",
  ACC: "acc",
  MENU: "menu",
  IDENTITY: "identity",
  SIGN: "signature",
  DETAILS: "details",
  SUCCESS: "success",
  REJECTED_IDS: "rejected_ids",
  ID_VERIFICATION_CHECK: "id_verification",
  EL_VERIFICATION: "el_verification",
  VIRTUAL_ID: "virtual_id",
  BACKY_PORTAL: 'backy_portal',
  DOCUMENT_SIGNING: 'document_signing',
  DOCUMENT_UPLOADS: 'document_upload',
  ID_VERIFICATION: 'identity_verification',
  AFP: 'afp'
}