import { useEffect, useState } from 'react';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCandidateApplication } from 'api/candidate-application-slice';
import { AnimatePresence } from 'framer-motion';
import { IdVerificationScreens } from './constants/id-verification-screens';
import Introduction from './Introduction/Introduction';
import QRScanning from './QRScanning/QRScanning';
import Selfie from './Selfie/Selfie';
import IDScanning from './IDScanning/IDScanning';
import ConfirmDetails from './ConfirmDetails/ConfirmDetails';
import VerificationResult from './VerificationResult/VerificationResult';
import { analytics } from 'services/segment';
import { useCandidateApplicationUploadActions } from 'hooks/upload-actions';
import { 
  useGetIdVerificationTypesQuery, 
  useLazyExtractIdDetailsQuery, 
  useCompareFacesMutation,
  useUpdateIdVerificationByTokenMutation,
  useDeleteIdVerificationByTokenMutation
} from 'api/id-verifications-api';


const CandidateIdVerification = () => {

  const navigate = useNavigate();

  const { 
    application: { 
      brand, 
      candidate, 
      uploads,
      id_verification
    },
    fetched 
  } = useSelector(selectCandidateApplication);

  const { token, tab } = useParams();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const [selfieUpload, setSelfieUpload] = useState();
  const [idUpload, setIdUpload] = useState();
  const [idDetails, setIdDetails] = useState([]);

  const { data: idTypes } = useGetIdVerificationTypesQuery();

  const [extractIdDetails] = useLazyExtractIdDetailsQuery();
  const [compareFaces] = useCompareFacesMutation();
  const [updateIdVerification] = useUpdateIdVerificationByTokenMutation();
  const [deleteIdVerification] = useDeleteIdVerificationByTokenMutation();

  const { signUpload, createUpload, deleteUpload } = useCandidateApplicationUploadActions(token);  

  useEffect(() => { analytics.track('identity_verification_started') }, [])

  // Verify the tab to redirect to
  useEffect(() => {
    if(!fetched) return;

    const selfieUpload = uploads.find(u => !!u.metadata?.selfie && !!u.metadata?.idv);
    const idUpload = uploads.find(u => !!u.metadata?.id_type && !!u.metadata?.idv);

    setSelfieUpload(selfieUpload)
    setIdUpload(idUpload)

    if(id_verification) {
      setSuccess(id_verification.face_matching > 90);
      redirect(IdVerificationScreens.Result);
    } else if(idUpload) {
      setLoading(true);
      handleExtractIdDetails(idUpload.id).then(() => {
        setLoading(false);
        redirect(IdVerificationScreens.ConfirmDetails);
      })
    } else if(selfieUpload) {
      redirect(IdVerificationScreens.IDScanning);
    } 
  }, [fetched])

  const redirect = (screen) => navigate(`/form_submission/candidate/identity_verification/${screen}/${token}`)  

  const handleExtractIdDetails = async(uploadId) => {
    const result = await extractIdDetails({ token, uploadId }).unwrap();
    setIdDetails(result);
  }

  const onDetailsConfirm = async(data) => {
    redirect(IdVerificationScreens.Result);
    setVerifying(true);
    await updateIdVerification({ token, payload: { details: data } })
    const { data: result } = await compareFaces({ token, sourceId: selfieUpload.id, targetId: idUpload.id });

    if(result?.face_matching > 90) {
      analytics.track('identity_verification_passed')
    } else {
      analytics.track('identity_verification_failed')
    }

    setSuccess(result);
    setVerifying(false);
  }

  const onRetry = async() => {
    setLoading(true);
    if(idUpload) await deleteUpload(idUpload.id);
    if(selfieUpload) await deleteUpload(selfieUpload.id);
    await deleteIdVerification({ token });
    redirect(IdVerificationScreens.Selfie);
    setLoading(false);
  }

  const bypassVerification = () => {
    analytics.track('identity_verification_bypassed')
    navigate(`/form_submission/candidate/menu/${token}`)
  }

  return ( 
    <CandidatePage loading={loading} token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        <AnimatePresence initial={false} mode='wait'>
          {tab === IdVerificationScreens.Introduction && 
            <Introduction brand={brand} redirect={redirect} />
          }
          {tab === IdVerificationScreens.QRScanning && 
            <QRScanning token={token} brand={brand} />
          }
          {tab === IdVerificationScreens.Selfie &&
            <Selfie 
              candidate={candidate}
              brand={brand}
              redirect={redirect}
              setUpload={setSelfieUpload}
              signUpload={signUpload}
              createUpload={createUpload}
            />
          }
          {tab === IdVerificationScreens.IDScanning &&
            <IDScanning 
              candidate={candidate}
              brand={brand}
              idTypes={idTypes}
              setUpload={setIdUpload}
              redirect={redirect}
              signUpload={signUpload}
              createUpload={createUpload}
              extractIdDetails={handleExtractIdDetails}
            />
          }
          {tab === IdVerificationScreens.ConfirmDetails &&
            <ConfirmDetails
              candidate={candidate}
              brand={brand}
              idTypes={idTypes}
              idUpload={idUpload}
              idDetails={idDetails}
              onConfirm={onDetailsConfirm}
            />
          }
          {tab === IdVerificationScreens.Result &&
            <VerificationResult
              success={success}
              brand={brand}
              isVerifying={verifying}
              onRetry={onRetry}
              onContinue={bypassVerification}
            />
          }
        </AnimatePresence>
      </CandidatePage.View>
    </CandidatePage>
  )
}

export default CandidateIdVerification;