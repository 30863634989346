import { useState } from 'react';
import Alert from 'components/Alert/Alert';
import { IconButton } from 'components/IconButton/IconButton';
import cn from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';
import { CandidateInformation } from './tabs/CandidateInformation/CandidateInformation';
import { CandidateCustomFields } from './tabs/CandidateCustomFields/CandidateCustomFields';
import { CandidateIdVerification } from './tabs/CandidateIdVerification/CandidateIdVerification';
import styles from './Candidate.module.scss';

export const Candidate = ({
  candidate,
  company,
  idVerification,
  teams = [],
  customFields,
  brand,
  handleCandidateUpdate = () => { },
  sendRequestEmail = () => { }
}) => {

  const [showRequestAlert, setShowRequestAlert] = useState(false);

  const onUpdate = async (data) => {
    await handleCandidateUpdate(data);
    setShowRequestAlert(!candidate.completed_at && !candidate.canceled);
  }

  const sendNewRequest = () => {
    setShowRequestAlert(false);
    sendRequestEmail();
  }

  return (
    <div className={cn('d-flex','card', 'card-with-border', 'card--small')}>
      <Alert
        show={showRequestAlert}
        title="Would you like to send a new request to the candidate?"
        ok="Send New Request"
        onOk={sendNewRequest}
        cancel="No Thanks"
        onCancel={() => setShowRequestAlert(false)}
      />
      <div className='u-width-100'>
        <div className={cn('d-flex', 'justify-content-between', 'align-items-center', 'u-padding')}>
          <div>
            <div className='title-3'>Candidate Information</div>
            <div className='t-small'>This information can be updated at any time</div>
          </div>
          {!candidate.completed_at && !candidate.canceled &&
            <IconButton icon='send' tip='Send new request' onClick={() => setShowRequestAlert(true)} disabled={candidate.partner_application_id && !candidate.partner_application_started} />
          }
        </div>

        <Tabs defaultActiveKey='information' className={cn(styles.tabs, 'tabs', 'padding-small', 'no-padding')} mountOnEnter unmountOnExit>
          <Tab eventKey='information' title='Information'>
            <CandidateInformation candidate={candidate} brand={brand} company={company} teams={teams} onUpdate={onUpdate} />
          </Tab>
          {customFields?.length > 0 &&
            <Tab eventKey='custom_fields' title='Custom Fields'>
              <CandidateCustomFields candidate={candidate} customFields={customFields} onUpdate={onUpdate} />
            </Tab>
          }
          {idVerification &&
            <Tab eventKey='id_verification' title='ID Verification'>
              <CandidateIdVerification idVerification={idVerification} />
            </Tab>
          }
        </Tabs>
      </div>
    </div>
  )

}