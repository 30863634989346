import { Input, PhoneNumberPicker, InputGroup, Button, Select } from 'components/FormComponents';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useEffect } from 'react';

export const CandidateInformation = ({ 
  candidate,
  company,
  brand,
  teams,
  onUpdate = () => {}
}) => {

  const { register, reset, control, handleSubmit } = useForm({ defaultValues: candidate });

  useEffect(() => reset(candidate), [candidate])

  const team_id = useWatch({ control, name: 'team_id' });

  return (
    <form onSubmit={handleSubmit(onUpdate)} className='background-secondary u-padding'>
      <div className='card card-with-border u-padding'>
        <div className="inline">
          <InputGroup title='First name'>
            <Input name='first_name' register={register} validators={{ required: true }} placeholder='John' />
          </InputGroup>
          <InputGroup title='Last name'>
            <Input name='last_name' register={register} validators={{ required: true }} placeholder='Doe' />
          </InputGroup>
        </div>
        <InputGroup title='Email'>
          <Input name='email' register={register} validators={{ required: true }} placeholder='john@checkmate.com' />
        </InputGroup>
        <InputGroup title='Contact Number'>
          <Controller
            control={control}
            name='contact_number'
            render={({ field: { onChange, value } }) => (
              <PhoneNumberPicker value={value} onChange={onChange} defaultCountry={company?.country} />
            )}
          />
        </InputGroup>
        <InputGroup title='Job title'>
          <Input name='job_role' register={register} placeholder='General Manager' />
        </InputGroup>
        <InputGroup title='Department'>
          <Input name='team_name' register={register} placeholder='Department' />
        </InputGroup>

        {candidate.cc_notification_emails &&
          <InputGroup title='CC Notification Emails'>
            <Input name='cc_notification_emails' register={register} />
          </InputGroup>
        }

        <InputGroup title='Brand'>
          <Input disabled={true} inputProps={{ value: brand?.name }} />
        </InputGroup>

        {teams.length > 1 &&
          <InputGroup title='Team' className='u-margin-bottom--large'>
            <Select name='team_id' placeholder='Team' register={register} value={team_id} useDefault>
              {teams.map(team => (
                <Select.Item key={team.id} value={team.id}>{team.name}</Select.Item>
              ))}
            </Select>
          </InputGroup>
        }

        <InputGroup title='Document'>
          <Input disabled={true} register={register} name='document_name' placeholder='-' />
        </InputGroup>

        <Button submit className='u-width-100 u-margin-top--large'>Update</Button>
      </div>
    </form>
  )
}