import { useEffect, useState } from 'react';
import { useGetRefereeBrandByTokenQuery, useSaveFollowUpQuestionMutation } from 'api/referees-api';
import { Loading } from 'components/Loading/Loading';
import { FormThanksCard } from 'components/FormThanksCard/FormThanksCard';
import { CustomButton } from '../CandidateApp/components/CustomButton/CustomButton';
import { useDocumentTitle } from 'hooks/document-title';
import styles from './RefereeCompletion.module.scss';
import { useParams } from 'react-router-dom';

export const RefereeCompletion = () => {

  useDocumentTitle('Checkmate - Reference Checking and Background Screening Software')

  const { token } = useParams();

  const { data: brand, isLoading: loadingBrand } = useGetRefereeBrandByTokenQuery(token);

  const [saveFollowUpQuestion] = useSaveFollowUpQuestionMutation();

  const [loading, setLoading] = useState(false);
  const [thanksState, setThanksState] = useState(false);

  useEffect(() => {
    if(!brand || brand.follow_up_question_active) return;

    if(brand.click_through_active && brand.click_through_referee_active) {
      redirect(brand.click_through_referee);
    } else {
      redirect("https://www.checkmate.tech/app/submission-confirmation");
    }
  }, [brand])

  const saveQuestion = async(follow_up_answer) => {
    setLoading(true);
    await saveFollowUpQuestion({ token, follow_up_answer });

    if(brand.click_through_active && brand.click_through_referee_active)
      redirect(brand.click_through_referee);
    else
      redirect("https://www.checkmate.tech/app/submission-confirmation");
  }

  const redirect = (url) => {
    setThanksState(true);
    setTimeout(() => window.location.replace(url), 3000);
  }

  return(
    <div className={styles.root}>
      <Loading active={loading || loadingBrand} />
      <div className={`${styles.content} u-max-width-content`}>
        {thanksState ?
          <FormThanksCard /> :
          <img className={styles.accountLogo} src={brand?.brand_active ? brand?.brand_logo : 'assets/images/checkmate/icon-label.svg'} alt="logo"/>
        }
        {brand?.follow_up_question_active &&
          <div className={styles.extraQuestion}>
            <div className={styles.text}>{brand.follow_up_question}</div>
            <div className={styles.buttonsOptions}>
              <CustomButton brand={brand} onClick={() => saveQuestion('No')}>No</CustomButton>
              <CustomButton brand={brand} onClick={() => saveQuestion('Yes')}>Yes</CustomButton>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default RefereeCompletion;
