import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { useForm, Controller } from 'react-hook-form';
import { Button, FilePicker, DatePicker, InputGroup, Input, ToggleSwitch } from 'components/FormComponents';
import { useApiUploadActions } from 'hooks/upload-actions';
import { DynamicTypeInput } from 'components/Form/DynamicTypeInput/DynamicTypeInput';
import { now } from 'helpers/date';
import { useEffect } from 'react';


export const SubCheckCompletionModal = ({
  check,
  subCheck,
  checkType,
  visible,
  onUpdate = () => { },
  onClose = () => { },
}) => {

  const { reset, handleSubmit, control, watch, register } = useForm();

  const { getUpload, signUpload, createUpload, deleteUpload } = useApiUploadActions();

  useEffect(() => reset({
    upload_id: subCheck.upload_id, 
    result_flag: subCheck.result_flag,
    extra_details: subCheck.extra_details,
    expiration_date: subCheck.expiration_date ? subCheck.expiration_date : '9999-12-31' 
  }), [subCheck])

  const onSubmit = (data) => {
    if (!subCheck.completed_at) data.completed_at = now();
    onUpdate(data);
  }

  return (
    <AnimatedModal visible={visible} onClose={onClose} title={`Complete ${subCheck?.title}`}>
      {visible &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup title='Result file' className='u-margin-top--large'>
            <Controller
              control={control}
              name='upload_id'
              render={({ field: { onChange, value } }) => (
                <FilePicker
                  metadata={{ sub_result: check.type, sub_check_id: subCheck.id }}
                  modelParams={{ candidate_id: check.candidate_id, result: true, candidate_check_id: check.id }}
                  bucketFolder="background_result"
                  className='u-margin-top'
                  accept={{ 'application/pdf': ['.pdf'] }}
                  value={value}
                  onChange={onChange}
                  getUpload={getUpload}
                  signUpload={signUpload}
                  createUpload={createUpload}
                  deleteUpload={deleteUpload}
                />
              )}
            />
          </InputGroup>

          <ToggleSwitch
            name='result_flag'
            register={register}
            className='u-margin-bottom'
            label='Has result'
          />

          <InputGroup title='Expiration date'>
            <Controller
              control={control}
              name='expiration_date'
              render={({ field: { onChange, value } }) => (
                <DatePicker value={value} onChange={onChange} outputFormat="YYYY-MM-DD" useDefaultToday={false} />
              )}
            />
          </InputGroup>

          {checkType?.extra_result_details?.map(extraDetail => (
            <DynamicTypeInput 
              key={extraDetail.code} 
              title={extraDetail.title}
              fieldName={`extra_details.${extraDetail.code}`}
              fieldOptions={extraDetail.options}
              type={extraDetail.type}
              control={control}
              register={register}
              watch={watch}
            />
          ))}

          <Button submit type='success' large className='u-width-100 u-margin-top--large'>
            {subCheck.completed_at ? 'Update' : 'Complete'}
          </Button>
        </form>
      }
    </AnimatedModal>
  )
}