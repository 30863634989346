import cn from 'classnames';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { motion } from 'framer-motion';
import { IdVerificationScreens } from '../constants/id-verification-screens';
import moduleStyles from './../CandidateIdVerification.module.scss';
import { useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayFrame } from 'components/OverlayFrame/OverlayFrame';
import Webcam from 'react-webcam';
import styles from './IDScanning.module.scss';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { uploadSigned } from 'helpers/aws-s3';
import { cropImage } from 'helpers/images';
import { CustomLink } from 'views/CandidateApp/components/CustomLink/CustomLink';
import { analytics } from 'services/segment';

const Screens = {
  IDSelection: 'id_selection',
  IDScanner: 'id_scanner',
  IDAnalysing: 'id_analysing',
}

const IDScanning = ({ 
  candidate, 
  idTypes = [],
  brand,
  setUpload = () => {},
  redirect = () => {},
  signUpload   = () => {},
  createUpload = () => {},
  extractIdDetails = () => {}
}) => {

  const [screen, setScreen] = useState(Screens.IDSelection);
  const [idType, setIdType] = useState();
  const [photo, setPhoto] = useState();

  const onCapture = async(photo, frameWidth, frameHeight) => {
    setPhoto(photo);
    setScreen(Screens.IDAnalysing);

    const croppedImage = await cropImage(photo, frameWidth, frameHeight);
    
    const signedUpload = await signUpload({
      file_name: `photo_id.jpeg`,
      file_type: 'image/jpeg',
      file_ext: 'jpeg',
      bucket_folder: 'background_applications'
    });

    await uploadSigned(signedUpload.url, croppedImage, "image/png", true);

    const upload = await createUpload({
      candidate_id: candidate.id,
      metadata: { id_type: idType.code, idv: true },
      file_name: signedUpload.file_name,
      file_type: signedUpload.file_type,
      hash_key: signedUpload.key
    });

    analytics.track('identity_verification_document_uploaded')

    setUpload(upload);

    await extractIdDetails(upload.id);

    redirect(IdVerificationScreens.ConfirmDetails);    
  }

  const onSelect = (idType) => {
    setIdType(idType);
    setScreen(Screens.IDScanner)
  }

  const renderPage = () => {
    switch(screen) {
      case Screens.IDSelection: 
        return (
          <IDSelection 
            brand={brand} 
            idTypes={idTypes}
            onSelect={onSelect} 
          />
        )
      case Screens.IDScanner: 
        return (
          <IDScanner
            brand={brand} 
            onCancel={() => setScreen(Screens.IDSelection)}
            onCapture={onCapture} 
          />
        )
      case Screens.IDAnalysing:
        return (
          <IDAnalysing brand={brand} photo={photo} />
        )
    }
  }

  return (
    <motion.div key={IdVerificationScreens.IDScanning} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {renderPage()}
    </motion.div>
  )
}


export default IDScanning;


const IDSelection = ({ 
  brand, 
  idTypes = [],
  onSelect = () => {},
}) => (
  <CandidatePage.Card className={cn('d-flex', 'align-items-center', 'justify-content-center', 'flex-column')}>
    <img src='assets/images/icons/id_icon.svg' className={moduleStyles.icon} />

    <h1 className={cn('title-4', 'u-margin-top--large')}>Step 2: Upload your ID</h1>
    <p className={cn('u-margin-top', 'u-text-centered', 't-small')}>
      On this page we'll need you to upload a copy or photogrph of your identification. You can either do this by taking a photograph using your mobile devices camera, or upload a file from your computer or mobile device.
    </p>

    <div className={styles.idsWrapper}>
      {idTypes.map(idType => (
          <OverlayTrigger key={idType.code} trigger={['hover', 'focus']} placement='top' overlay={<Tooltip>{idType.name}</Tooltip>}>
            <div>
              <CustomButton 
                key={idType.name} 
                small
                type="secondary" 
                brand={brand}
                className={styles.idButton}
                onClick={() => onSelect(idType)}
              >
                <span className={styles.idButtonText}>{idType.name}</span>
              </CustomButton>
            </div>
          </OverlayTrigger>
      ))}
    </div>

    <p className={cn('t-small', 'u-text-centered', 'u-margin-top--large')}>For help and support with your ID documents, <CustomLink brand={brand} onClick={() => window.Intercom('show')}>reach out</CustomLink> to our support team.</p>

  </CandidatePage.Card>
)


const IDScanner = ({
  brand,
  onCapture = () => {},
  onCancel = () => {}
}) => {

  const cameraRef = useRef();
  const frameRef = useRef();

  const capture = () => {
    const frameRec = frameRef.current.getFrameRec();
    onCapture(cameraRef.current.getScreenshot(), frameRec.width, frameRec.height);
  }

  return (
    <div className={styles.frameContainer}>
      <Webcam 
        ref={cameraRef}
        audio={false}
        screenshotFormat="image/jpeg"
        className={styles.image}
        videoConstraints={{
          facingMode: 'environment'
        }}
      />

      <OverlayFrame 
        ref={frameRef}
        header='🪪 Take a photo of your ID front side'
        subheader='Hold your ID up to the camera viewport'
        strokeColor={brand?.brand_active && brand?.color_active ? brand.color_button : '#b5abca'}
      />

      <div className={styles.buttons}>
        <CustomButton type='secondary' small className={cn('u-margin-right', 'u-margin-top--0')} onClick={onCancel}>Cancel</CustomButton>
        <CustomButton brand={brand} small className='u-margin-top--0' onClick={capture}>Take Photo</CustomButton>
      </div>
    </div>
  )
}

const IDAnalysing = ({
  brand,
  photo
}) => (
  <div className={styles.frameContainer}>
    <img src={photo} className={styles.image} /> :

    <OverlayFrame 
      header='🪪 Take a photo of your ID front side'
      subheader='🧐 Analysing your ID...'
      strokeColor={brand?.brand_active && brand?.color_active ? brand.color_button : '#b5abca'}
      loading={true}
    />
  </div>
)