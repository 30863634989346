import FeatherIcon from 'feather-icons-react';
import styles from './CandidateIdVerification.module.scss';
import cn from 'classnames';
import { useMemo, useEffect } from 'react';
import { useIncrementalNumber } from 'hooks/incremental-number';
import { IconButton } from 'components/IconButton/IconButton';
import { saveAs } from 'file-saver';
import { Badge } from 'react-bootstrap';

export const CandidateIdVerification = ({ idVerification }) => {

  const [incrementalPercentage, reset] = useIncrementalNumber(idVerification?.face_matching)

  useEffect(() => { reset() }, []);


  const matchingStatus = useMemo(() => {
    if(!idVerification) return 'bad';

    if(idVerification?.face_matching < 45) return 'bad';
    else if(idVerification?.face_matching < 75) return 'average';
    else return 'success';
  }, [idVerification])

  return (
    <div className={cn(styles.root, 'background-secondary', 'u-padding')}>
      <div className='card card-with-border u-padding'>
        <div className='d-flex align-items-center justify-content-between'>
          <h1 className="title-4 u-bold d-inline-flex align-items-center"><FeatherIcon size={18} className="u-margin-right--small" icon='user-check' /> Face match</h1>
          <div className={cn(styles.badge, styles[`badge--${matchingStatus}`])}><FeatherIcon size={16} icon='check' />{incrementalPercentage}%</div>
        </div>
        <Bar percentage={idVerification?.face_matching} status={matchingStatus} />
        <div className={cn('d-flex', 'justify-content-between', 'align-items-start', 'gap-small', 'u-margin-top--large')}>
          <ImageCard upload={idVerification.selfie} title='Selfie Image' icon='user' />
          <ImageCard upload={idVerification.id_doc} title='ID Document' icon='file-text' />
        </div>
        <div className='u-margin-top'>
          <h1 className='title-4 u-bold'>Personal Information</h1>
          <div className={styles.detailsValues}>
            <div className={styles.detailValue}>
              <span className='title-6'>Given Name</span>
              <span className='t-body'>{idVerification.details?.name.givenName}</span>
            </div>
            <div className={styles.detailValue}>
              <span className='title-6'>Middle Name</span>
              <span className='t-body'>{idVerification.details?.name.middleName}</span>
            </div>
            <div className={styles.detailValue}>
              <span className='title-6'>Family Name</span>
              <span className='t-body'>{idVerification.details?.name.familyName}</span>
            </div>
            <div className={styles.detailValue}>
              <span className='title-6'>DOB</span>
              <span className='t-body'>{idVerification.details?.dateOfBirth.dateOfBirth}</span>
            </div>
            <div className={styles.detailValue}>
              <span className='title-6'>Country</span>
              <span className='t-body'>{idVerification.details?.addresses[0].country}</span>
            </div>
          </div>
        </div>
        <div className='u-margin-top'>
          <h1 className='title-4 u-bold'>
            Identity Document 
            {idVerification.status === 'NO_MATCH' && <Badge className='u-status-declined u-margin-left'>Failed</Badge>}
            {idVerification.status === 'CHECKED_SUCCESS_CLEAR' && <Badge className='u-status-completed u-margin-left'>Success</Badge>}
            {idVerification.status === 'CHECKED_PARTIAL_SUCCESS' && <Badge className='u-status-pending u-margin-left'>Partial Success</Badge>}
          </h1>
          <div className={styles.detailsValues}>
            <div className={styles.detailValue}>
              <span className='title-6 '>Country</span>
              <span className='t-body'>{idVerification.details?.identityDocs[0].country}</span>
            </div>
            <div className={styles.detailValue}>
              <span className='title-6'>ID Number</span>
              <span className='t-body'>{idVerification.details?.identityDocs[0].idNumber}</span>
            </div>
            {idVerification.details?.identityDocs[0]?.extraData && (
              <div className={styles.detailValue}>
                <span className='title-6'>Extra Data</span>
                {idVerification.details?.identityDocs[0]?.extraData.map(data => (
                  <>
                    <span className='t-body u-capitalize'>{data.kvpKey.replace('_', ' ')}</span>
                    <span className='t-body'>{data.kvpValue}</span>
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}


const Bar = ({ percentage, status }) => {

  const [incrementalPercentage, reset] = useIncrementalNumber(percentage)
  useEffect(() => { reset() }, []);

  return (
    <div className={cn(styles.bar, 'u-margin-top', styles[`bar--${status}`])}>
      <div style={{ width: `${incrementalPercentage}%` }} className={styles.barProgress} />
      <div className={styles.barPercentageTags}>
        <span className={styles.barPercentageTag}>0%</span>
        <span className={styles.barPercentageTag}>50%</span>
        <span className={styles.barPercentageTag}>100%</span>
      </div>
    </div>
  )
}


const ImageCard = ({ upload, title, icon }) => {
  
  const download = () => {
    saveAs(upload.url, upload.file_name);
  }

  return (
    <div className={styles.imageCard}>
      <div className={cn('d-flex', 'justify-content-between', 'u-padding--small')}>
        <div className={cn('d-flex', 'align-items-center')}>
          <FeatherIcon icon={icon} size={18} />
          <span className='title-5 u-semi-bold u-padding-left--small'>{title}</span>
        </div>
        <IconButton icon='download' type='white' small onClick={download} />
      </div>
      <img src={upload.url} className={styles.photo} />
    </div>
  )
}