import styles from './Introduction.module.scss';
import cn from 'classnames';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { motion } from 'framer-motion';
import { IdVerificationScreens } from '../constants/id-verification-screens';
import moduleStyles from './../CandidateIdVerification.module.scss';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { useUserAgent } from 'hooks/user-agent';

const Introduction = ({
  brand,
  redirect = () => {},
}) => {

  const { mobile } = useUserAgent()

  const onRedirect = () => {
    if(mobile) {
      redirect(IdVerificationScreens.Selfie);
    } else {
      redirect(IdVerificationScreens.QRScanning);
    }
  }

  return (
    <motion.div 
      key={IdVerificationScreens.Introduction}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <CandidatePage.Card className={cn('d-flex', 'align-items-center', 'justify-content-center', 'flex-column')}>
        <img src='assets/images/icons/face-scanner.svg' className={moduleStyles.icon} />
        <h1 className={cn('title-4', 'u-margin-top--large')}>Verify your Identity</h1>
        <p className={cn('u-margin-top', 'u-text-centered', 't-small')}>
          Lets get started by verifying your identity. In this module you'll be asked to provide the following.
        </p>
        <ul className={cn(styles.list, 'u-margin-top')}>
          <li className='u-padding-bottom--small'>✅ A selfie photo</li>
          <li>✅ A photo of a valid ID type</li>
        </ul>
        <CustomButton brand={brand} small className={cn('u-width-100', 'u-margin-top--large' )} onClick={onRedirect}>Let's Verify</CustomButton>
      </CandidatePage.Card>
    </motion.div>
  )
}

export default Introduction;