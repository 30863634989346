import cn from 'classnames';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { motion } from 'framer-motion';
import { IdVerificationScreens } from '../constants/id-verification-screens';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import Lottie from 'react-lottie';
import * as animationData from 'assets/animations/scanning.json';

const VerificationResult = ({ 
  brand,
  success,
  isVerifying = false,
  onRetry    = () => {},
  onContinue = () => {}
}) => {
  return (
    <motion.div 
      key={IdVerificationScreens.Result}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <CandidatePage.Card className={cn('d-flex', 'align-items-center', 'justify-content-center', 'flex-column', 'u-max-width-medium')}>
        {isVerifying ?
          <Loading /> : 
          <Result 
            brand={brand}
            success={success}
            onContinue={onContinue}
            onRetry={onRetry}
          />
        }
      </CandidatePage.Card>
    </motion.div>
  )
}


const Loading = () => (
  <>
   <Lottie 
      width={160}
      options={{
        loop: true,
        autoplay: true, 
        animationData: animationData
      }} 
    />
    <h1 className={cn('title-4', 'u-margin-top', 'u-text-centered')}>Hold tight while we verify your identity.</h1>
    <p className={cn('u-margin-top', 'u-text-centered', 't-small')}>This might take a few seconds...</p>
  </>
)

const Result = ({
  success, 
  brand, 
  onRetry = () => {},
  onContinue = () => {} 
}) => (
  <>
    <img src={`assets/images/icons/${success ? 'face-scanner-success' : 'face-scanner-error'}.svg`} style={{ width: 100 }} />
    <h1 className={cn('title-4', 'u-margin-top--large', 'u-text-centered')}>{success ? '✅ Your identity has been verified' : '🛑 ID verification unsuccessful'}</h1>
    <p className={cn('u-margin-top', 'u-text-centered', 't-small')}>
      {success ? 
        "Success, your identification details have now been verified." : 
        "We apologise, but we weren't able to verify your identity. Please try again, or contact support for assistance"
      }
    </p>
    {success ?
      <CustomButton small brand={brand} className={cn('u-width-100', 'u-margin-top--large')} onClick={onContinue}>Continue</CustomButton> :
      <>
        <CustomButton small brand={brand} className={cn('u-width-100', 'u-margin-top--large')} onClick={onRetry}>Try again</CustomButton>
        <CustomButton small type='secondary' className='u-width-100' onClick={onContinue}>Continue without verifying</CustomButton>
      </>
    }
    
  </>
)

export default VerificationResult;