import cn from 'classnames';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { Input, Select, InputGroup, DatePicker } from 'components/FormComponents';
import { DynamicTypeInput } from 'components/Form/DynamicTypeInput/DynamicTypeInput';
import { motion } from 'framer-motion';
import styles from './ConfirmDetails.module.scss';
import { IdVerificationScreens } from '../constants/id-verification-screens';
import moduleStyles from './../CandidateIdVerification.module.scss';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { Alpha3Countries } from "constants/countries";
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';

const ConfirmDetails = ({
  idTypes=[],
  idDetails,
  idUpload,
  brand,
  onConfirm = () => {},
}) => {

  const { register, watch, control, handleSubmit, reset, formState: { errors } } = useForm();

  const [idType, setIdType] = useState();

  useEffect(() => {
    if(!idUpload || !idDetails) return;
    const idType = idTypes.find(id => id.code === idUpload.metadata.id_type);
    setIdType(idType);

    reset({
      name: {
        givenName: idDetails.first_name,
        middleName: idDetails.middle_name,
        familyName: idDetails.last_name
      },
      dateOfBirth: {
        dateOfBirth: idDetails.dob
      }, 
      addresses: [
        { country: 'NZL' }
      ],
      identityDocs: [
        {
          idType: idType.type,
          idNumber: idDetails.document_number
        }
      ]
    })
  }, [idUpload, idDetails])
  
  return (
    <motion.div 
      key={IdVerificationScreens.ConfirmDetails}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <CandidatePage.Card className={cn(styles.root, 'd-flex', 'align-items-center', 'justify-content-center', 'flex-column')}>
        <img src='assets/images/icons/thumbs-up.svg' className={moduleStyles.icon} />
        <h1 className={cn('title-4', 'u-margin-top--large')}>Confirm your ID details</h1>
        <p className={cn('u-margin-top', 'u-text-centered', 't-small')}>
          Please confirm your identification details are correct. Please ensure all the information is accurate as this is shared directly with the relevant government entities.
        </p>
        <img src={idUpload?.url} className={cn('u-margin-y--large', styles.thumbnail)} /> 

        <form className='u-width-100' onSubmit={handleSubmit(onConfirm)}>
          <InputGroup title='First Name *'>
            <Input 
              name='name.givenName' 
              register={register} 
              validators={{ required: true }}
              error={errors?.name?.givenName?.type}
            />
          </InputGroup>
          <InputGroup title='Middle Name'>
            <Input 
              name='name.middleName'
              register={register} 
            />
          </InputGroup>
          <InputGroup title='Last Name *'>
            <Input 
              name='name.familyName' 
              register={register} 
              validators={{ required: true }}
              error={errors?.name?.familyName?.type}
            />
          </InputGroup>
          <InputGroup title='Date of Birth *'>
            <Controller
              control={control}
              name='dateOfBirth.dateOfBirth'
              validators={{ required: true }}
              error={errors?.dateOfBirth?.dateOfBirth?.type}
              render={({ field: { onChange, value } }) => (
                <DatePicker value={value} onChange={onChange} outputFormat="YYYY-MM-DD" format='dd/MM/YYYY'/>
              )}
            />
          </InputGroup>
          <InputGroup title='Country of residence *'>
            <Select 
              name='addresses[0].country'
              placeholder='Select Country of residence...' 
              register={register}
              value={watch('addresses[0].country')}
              useSearch
            >
              {Alpha3Countries.map(country => (
                <Select.Item key={country.code} value={country.code}>{country.name}</Select.Item>
              ))}
            </Select>
          </InputGroup>
          <h3 className='title-6 u-margin-top--large u-margin-bottom--small'>ID Details</h3>
          <div className="card card-with-border u-padding">
            <Input register={register} name='identityDocs[0].idType' inputProps={{ hidden: true }} />
            {idType?.params?.map(param => (
              <DynamicTypeInput 
                key={param.code} 
                title={`${param.name} *`}
                fieldName={`identityDocs[0].${param.code}`}
                error={errors?.identityDocs?.[0]?.[param.code]?.type}
                fieldOptions={param.options}
                required
                type={param.type}
                control={control}
                register={register}
                watch={watch}
              />
            ))}
            {idType?.extra_params?.map((param, i) => (
              <div key={param.name}>
                <Input register={register} name={`identityDocs[0].extraData[${i}].kvpKey`} inputProps={{ hidden: true, value: param.code }} />
                <DynamicTypeInput 
                  title={`${param.name} *`}
                  fieldName={`identityDocs[0].extraData[${i}].kvpValue`}
                  fieldOptions={param.options}
                  required
                  error={errors?.identityDocs?.[0]?.extraData?.[i]?.kvpValue?.type}
                  type={param.type}
                  control={control}
                  register={register}
                  watch={watch}
                />
              </div>
            ))}
          </div>
          <CustomButton brand={brand} small className={cn('u-width-100', 'u-margin-top--large' )} submit={true}>Confirm Details</CustomButton>
        </form>
      </CandidatePage.Card>
    </motion.div>
  )
}

export default ConfirmDetails;