import React from 'react';
import cn from 'classnames';

export const CustomLink = ({ 
  brand, 
  children, 
  className, 
  href, 
  target='_blank', 
  onClick = () => {}
}) => (
  <a
    style={(brand?.brand_active && brand?.color_active) ? { color: brand.color_link } : null}
    className={cn('u-pointer', { [className]: className })}
    href={href}
    target={target}
    onClick={onClick}
  >
    {children}
  </a>
)