export const ID_TYPES = {
  drivers_license: { 
    code: "drivers_license", 
    title: "Drivers License", 
    prefix: "the front of",
    hasPhoto: true,
    complementary_id: { code: "drivers_license_back", prefix: "the back of", title: "Drivers License" } 
  },
  passport: { 
    code: "passport", 
    title: "Passport",
    hasPhoto: true,
  },
  nz_passport: { 
    code: "nz_passport", 
    title: "NZ Passport",
    hasPhoto: true,
  },
  au_passport: { 
    code: "au_passport", 
    title: "AU Passport",
    hasPhoto: true,
  },
  foreign_passport: { 
    code: "foreign_passport", 
    title: "Foreign passport",
    hasPhoto: true,
    complementary_id: { code: "visa_document", title: "Visa document" }
  },
  firearm_license: { 
    code: "firearm_license",
    title: "Firearm License"
  },
  card_18: {
    code: "card_18",
    title: "+18 card",
    hasPhoto: true,
  },
  birth_certificate: {
    code: "birth_certificate",
    title: "Birth Certificate"
  },
  citizenship_certificate: { 
    code: "citizenship_certificate", 
    title: "Citizenship Certificate" 
  },
  centrelink_card: { 
    code: "centrelink_card",
    title: "Centrelink card" 
  },
  certificate_of_identity: { 
    code: "certificate_of_identity", 
    title: "Certificate of ID for entry to Australia"
  },
  tertiary_student_card: { 
    code: "tertiary_student_card", 
    title: "Tertiary Student Identification Card"
  },
  au_photo_id_card: { 
    code: "au_photo_id_card",
    title: "ID card issued for regulatory purposes",
    hasPhoto: true,
  },
  government_employee_id: { 
    code: "government_employee_id", 
    title: "Government employee ID" 
  },
  defense_force_id_card: { 
    code: "defense_force_id_card", 
    title: "Defence Force Identity Card"
  },
  dva_card: {
    code: "dva_card", 
    title: "Department of Veterans Affairs card"
  },
  id: { 
    code: "id",
    title: "Photo ID",
    hasPhoto: true,
  },
  white_card: { 
    code: "white_card", 
    title: "Construction Induction Training Card (White card)"
  }
};

export const RESULT_OPTIONS = {
  File: 'file',
  Link: 'link',
  None: 'none',
}
