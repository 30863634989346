import { useEffect } from 'react';
import { Button } from 'components/FormComponents';
import { useForm } from 'react-hook-form';
import { CustomFields } from 'views/Account/tabs/CustomFields/components/CustomFields/CustomFields';

export const CandidateCustomFields = ({ 
  candidate,
  customFields,
  onUpdate = () => {}
}) => {

  const { register, watch, reset, control, handleSubmit } = useForm({ defaultValues: candidate });

  useEffect(() => reset(candidate), [candidate])

  return (
    <form onSubmit={handleSubmit(onUpdate)} className='background-secondary u-padding'>
      <div className='card card-with-border u-padding'>
        <CustomFields fields={customFields} watch={watch} register={register} control={control}/>
        <Button submit className='u-width-100 u-margin-top--large'>Update</Button>
      </div>
    </form>
  )
}